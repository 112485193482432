<template>
  <div id="settings">
    <h1 class="title">Settings</h1>
    <article class="message">
      <div class="message-header">
        <p>Calendar</p>
      </div>
      <div class="message-body">
        <p>
          You can create a link to add your events from your garden to your
          favorite calendar application.
        </p>
        <div class="field">
          <label class="checkbox">
            <input
              type="checkbox"
              :disabled="!intialLoadComplete"
              v-model="addBirthdays"
              @change="updatePreferances"
            />
            Add Birthdays to calendar link
          </label>
        </div>
        <!-- <div class="field" v-if="addBirthdays" style="margin-left: 20px">
          <input
            type="checkbox"
            v-model="enableBirthdayNotifications"
            @change="updatePreferances"
          />
          Add calendar noficiation for
          <input
            type="number"
            v-model="birthdayNotificationDays"
            @change="updatePreferances"
          />
          days before
        </div> -->
        <div class="field">
          <label class="checkbox">
            <input
              type="checkbox"
              :disabled="!intialLoadComplete"
              v-model="addReminders"
              @change="updatePreferances"
            />
            Add Reminders to calendar link
          </label>
        </div>
        <!-- <div class="field" v-if="addReminders" style="margin-left: 20px">
          <input
            type="checkbox"
            v-model="enableReminderNotifications"
            @change="updatePreferances"
          />
          Add calendar noficiation for
          <input
            type="number"
            v-model="reminderNotificationDays"
            @change="updatePreferances"
          />
          days before
        </div> -->
        <p>Add the following calendar to your favorite calendar tool</p>
        <div class="field">
          <a v-if="!link" class="button" @click="generateLink">Generate Link</a>
          <input
            class="input"
            v-else
            :value="displayURL"
            @click="$event.target.select()"
          />
        </div>
        <div class="notification content is-link">
          <strong>Looking for notifications?</strong>
          <p>
            You can add add a default notification in Google Calendar by going
            to Settings > selecting the calendar and adding an "Event
            Notification"
          </p>
        </div>
      </div>
    </article>
    <article class="message">
      <div class="message-header">
        <p>SMS Notifications</p>
      </div>
      <div class="message-body content">
        <p>
          SMS notification send you an SMS a specified number of days before an
          event.
        </p>
        <div class="field is-horizontal" style="margin-left: 20px">
          <label class="label" style="width:50%">Phone number:</label>
          <input
            class="input"

            type="tel"
            v-model="phoneNumber"
            @change="updatePhone"
          />

        </div>
        <div class="field" style="margin-left: 20px">
          <input
            type="checkbox"
            v-model="enableBirthdayNotifications"
            @change="updateNotifications"
          />
          Add SMS notification for
          <input
            style="display: inline; width: 50px"
            type="number"
            v-model="birthdayNotificationDays"
            @change="updateNotifications"
          />
          days before a <strong>birthday</strong>.
        </div>
        <div class="field" style="margin-left: 20px">
          <input
            type="checkbox"
            v-model="enableReminderNotifications"
            @change="updateNotifications"
          />
          Add SMS notification for
          <input
            style="display: inline; width: 50px"
            type="number"
            v-model="reminderNotificationDays"
            @change="updateNotifications"
          />
          days before a <strong>reminder</strong>.
        </div>
      </div>
    </article>
  </div>
</template>

<script>
import { db, user } from '../components/firebase.js'
import cryptoRandomString from 'crypto-random-string'
import { computed, ref } from 'vue'
export default {
  setup (props) {
    const link = ref('')
    const displayURL = computed(
      () =>
        `https://us-central1-relationship-garden.cloudfunctions.net/calendar?id=${link.value}`
    )
    const intialLoadComplete = ref(false)
    const addReminders = ref(false)
    const addBirthdays = ref(false)
    const enableBirthdayNotifications = ref(false)
    const enableReminderNotifications = ref(false)
    const reminderNotificationDays = ref(2)
    const birthdayNotificationDays = ref(2)
    const phoneNumber = ref('')
    db.collection('users')
      .doc(user.value.uid)
      .get()
      .then((res) => {
        const userData = res.data()
        if (userData.calendar) {
          if (userData.calendar.id) {
            link.value = userData.calendar.id
          }
          if (userData.calendar.contents) {
            if (userData.calendar.contents.includes('birthdays')) {
              addBirthdays.value = true
            }
            if (userData.calendar.contents.includes('reminders')) {
              addReminders.value = true
            }
          }
        }
        if (userData.notifations) {
          if ('birthdays' in userData.notifations) {
            enableBirthdayNotifications.value = true
            birthdayNotificationDays.value = userData.notifations.birthdays
          }
          if ('reminders' in userData.notifations) {
            enableReminderNotifications.value = true
            reminderNotificationDays.value = userData.notifations.reminders
          }
        }
        if (userData.phone) {
          phoneNumber.value = userData.phone
        }
        intialLoadComplete.value = true
      })
    async function updatePreferances () {
      const temp = []
      if (addReminders.value) {
        temp.push('reminders')
      }
      if (addBirthdays.value) {
        temp.push('birthdays')
      }
      await db.collection('users').doc(user.value.uid).update({
        'calendar.contents': temp
      })
    }
    async function updateNotifications () {
      const temp = {}
      if (enableBirthdayNotifications.value) {
        temp.birthdays = birthdayNotificationDays.value
      }
      if (enableReminderNotifications.value) {
        temp.reminders = reminderNotificationDays.value
      }

      await db.collection('users').doc(user.value.uid).update({
        notifications: temp
      })
    }
    async function updatePhone () {
      await db.collection('users').doc(user.value.uid).update({
        phone: phoneNumber.value
      })
    }
    async function generateLink () {
      const temp = cryptoRandomString({ length: 128, type: 'url-safe' })

      await db.collection('users').doc(user.value.uid).update({
        'calendar.id': temp
      })
      link.value = temp
    }
    return {
      generateLink,
      link,
      intialLoadComplete,
      addReminders,
      addBirthdays,
      updatePreferances,
      displayURL,
      enableBirthdayNotifications,
      enableReminderNotifications,
      reminderNotificationDays,
      birthdayNotificationDays,
      updatePhone,
      updateNotifications,
      phoneNumber
    }
  }
}
</script>

<style scoped>
#settings {
  text-align: left;
}
</style>>
